/** @jsx jsx */
import { jsx, Flex, Box, Styled } from 'theme-ui';
import React, { useCallback } from 'react';
import { getTranslate } from 'react-localize-redux';
import { useSelector, useDispatch } from 'react-redux';
import Layout from '../components/layout';
import Container from '../components/Container';
import BasketItem from '../parcelFlow/BasketItem';
import { formatPrice } from '../utils/formatters';
import Button from '../components/Button';
import FullHeightColumn from '../components/FullHeightColumn';
import { EmissionIcon, InfoIcon } from '../components/Icon';
import { BasketState, useCartHasOnlyExpressParcels } from '../state/basket';
import { startExpressParcelFlow } from '../state/parcelFlow';
import { locNavigate, prefetchPage } from '../state/session';
import AddDiscountCode from '../parcelFlow/AddDiscountCode';
import DiscountCodeRow from '../parcelFlow/DiscountCodeRow';
import PickupSelection from '../parcelFlow/PickupSelection';
import * as analytics from '../utils/analytics';
import { useState } from 'react';
import { Link } from '../components';

const isTest = process.env.GATSBY_ACTIVE_ENV !== 'production';

function computeTotalPriceAndDiscount(basket, discountPercent) {
  let total = 0;
  (basket.items || []).forEach(item => {
    total += item.product.price || 0;
    (item.services || []).forEach(s => {
      total += s.price || 0;
    });
  });

  if (basket.pickup) {
    total += basket.pickup.price;
  }

  if (basket.discount) {
    if (basket.discount.amount) {
      const units = basket.discount.units || 1;
      return [total, Math.min(total, units * basket.discount.amount)];
    } else if (basket.discount.percentage) {
      const amount = +((total * basket.discount.percentage) / 100.0).toFixed(2);
      return [total, Math.min(total, amount)];
    }
    return [total, 0.0];
  } else if (discountPercent) {
    const discountAmount = +(total * (discountPercent / 100)).toFixed(2);
    return [total, discountAmount];
  } else {
    return [total, 0.0];
  }
}

const DiscountPercent = ({ percent, amount }) => {
  const translate = getTranslate(useSelector(state => state.localize));

  return (
    <Box
      sx={{
        py: [2, 3],
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: 'border',
      }}
    >
      <Flex sx={{ width: '100%', alignItems: 'flex-start', my: 3 }}>
        <div sx={{ flexGrow: 1 }}>
          {translate('discount.percent')} {percent}%
        </div>
        <div sx={{ color: 'primary', fontWeight: 'medium' }}>{formatPrice(-amount)}</div>
      </Flex>
    </Box>
  );
};

export default ({ pageContext }) => {
  analytics.usePageCategory('paketit');
  const translate = getTranslate(useSelector(state => state.localize));
  const basket = useSelector(state => state.basket) || {};
  const discountPercent = useSelector(state => (state.session.user || {}).discountPercent);
  const dispatch = useDispatch();

  const [showEmissionDetails, setShowEmissionDetails] = useState(false);

  const freePickupRejected = useSelector(state => {
    const anyParcelIncludesPickup = state.basket.items.some(
      item =>
        !item.buySeries && (item.product.pickupIncluded || (item.services || []).some(s => s.override?.pickupIncluded))
    );
    const pickupRejected = state.basket.noPickup;
    return anyParcelIncludesPickup && pickupRejected;
  });

  const askSenderInfo = useCallback(() => {
    if (freePickupRejected) {
      dispatch(locNavigate('/departure-place'));
    } else {
      dispatch(locNavigate('/sender'));
    }
  }, [freePickupRejected, dispatch]);

  const onlyExpressParcels = useCartHasOnlyExpressParcels();
  const hasAbroadShipments = (basket.items || []).some(item => !['FI', 'AX'].includes(item.country));
  const showEmissionCompensation = (isTest || new Date().toISOString() >= '2022-07-01') && !hasAbroadShipments;

  const [totalPrice, discountAmount] = computeTotalPriceAndDiscount(basket, discountPercent);

  const addNewItem = useCallback(() => {
    if (onlyExpressParcels) {
      dispatch(startExpressParcelFlow());
    } else {
      dispatch(locNavigate('/osta-paketti'));
    }
  }, [onlyExpressParcels, dispatch]);

  dispatch(prefetchPage('/lahettaja'));

  if (!basket.items || basket.items.length === 0) {
    return (
      <Layout paths={pageContext.paths} locale={pageContext.locale || 'en'}>
        <Container variant="parcel">
          <FullHeightColumn>
            <Flex
              sx={{
                flex: 1,
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <svg width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.308 40a4 4 0 110 8 4 4 0 010-8zm20 0a4 4 0 110 8 4 4 0 010-8zm-20 2a2 2 0 100 4 2 2 0 000-4zm20 0a2 2 0 100 4 2 2 0 000-4zM2.064 0a5 5 0 014.893 3.97l1.268 6.029L44.69 10a3 3 0 012.258 1.024l.126.155a3 3 0 01.51 2.61L43.48 28.841A7 7 0 0136.726 34l-23.449-.001.342 1.619a3 3 0 002.751 2.376l.184.006h22.754a1 1 0 010 2H16.554a5 5 0 01-4.893-3.97l-5.22-24.795a.993.993 0 01-.004-.021L4.999 4.382A3 3 0 002.064 2h-.756a1 1 0 110-2zM44.69 12l-36.044-.001 4.21 20 23.87.001a5 5 0 004.76-3.47l.063-.215 4.106-15.052A1 1 0 0044.69 12z"
                  fill="#001E60"
                />
              </svg>{' '}
              <Box sx={{ fontWeight: 'medium', fontSize: 2, mt: 3 }}>{translate('basket.empty')}</Box>
            </Flex>
          </FullHeightColumn>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout
      title={translate('basket.title')}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
      checkShopDisturbance={true}
      showNavigation={false}
    >
      <Container>
        <FullHeightColumn>
          <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate('basket.title')}</Styled.h1>
          <Box sx={{ maxWidth: 800 }}>
            {basket.items.map(i => (
              <BasketItem key={i.rowId} item={i} />
            ))}
            <PickupSelection />
            {!!basket.discount ? (
              <DiscountCodeRow discount={basket.discount} amount={discountAmount} />
            ) : (
              discountPercent && <DiscountPercent percent={discountPercent} amount={discountAmount} />
            )}
            {onlyExpressParcels && (
              <Box sx={{ p: 2, mt: 3, mb: 2, bg: 'paleYellow' }}>{translate('buyParcel.express.basketNote')}</Box>
            )}
            <Flex sx={{ fontWeight: 'medium', my: [3], alignItems: 'flex-end' }}>
              <span sx={{ flexGrow: 1 }}>{translate('basket.total')}</span>
              <span sx={{ fontSize: [3], fontWeight: 'heading', color: 'primary' }}>
                {formatPrice(totalPrice - discountAmount)}
              </span>
            </Flex>
            {showEmissionCompensation && (
              <>
                <Flex
                  sx={{
                    mt: 3,
                    mb: 4,
                    px: 2,
                    py: 2,
                    maxWidth: 900,
                    alignItems: 'center',
                    borderRadius: [0, 1],
                    bg: 'greenLighter',

                    flexDirection: 'row',
                  }}
                >
                  <EmissionIcon sx={{ mr: 2 }} width={66} height={42} />

                  <Box sx={{ flexDirection: 'row' }}>
                    <Box>
                      <span sx={{ color: 'black' }}>{translate('buyParcel.product.emissionCompensation')}</span>
                    </Box>
                    <Button
                      variant="plain"
                      sx={{ p: 0, textDecoration: 'underline' }}
                      onClick={() => setShowEmissionDetails(!showEmissionDetails)}
                    >
                      {translate('readMore')}
                    </Button>
                  </Box>
                </Flex>
                {showEmissionDetails ? (
                  <Flex
                    sx={{
                      mt: 3,
                      mb: 4,
                      px: 3,
                      py: 3,
                      maxWidth: 900,

                      borderRadius: [0, 1],
                      bg: 'blueLighter',

                      flexDirection: 'row',
                    }}
                  >
                    <Box sx={{ mr: '60px' }}>
                      <InfoIcon />
                    </Box>
                    <Box sx={{ flexDirection: 'row' }}>
                      <p sx={{ p: 0, m: 0, mb: 3 }}>
                        {translate('buyParcel.product.emissionCompensationDetails.text1')}
                      </p>
                      <Link
                        to="/vastuullisuus/hiilineutraalit-pakettikuljetukset"
                        sx={{ textDecoration: 'underline', fontWeight: 'semibold' }}
                        target="_blank"
                        noTitleFix
                      >
                        {translate('buyParcel.product.emissionCompensationDetails.text2')}
                      </Link>
                    </Box>
                  </Flex>
                ) : null}
              </>
            )}
            {!basket.discount && <AddDiscountCode />}
            <Box sx={{ mt: 3, color: 'black' }} dangerouslySetInnerHTML={{ __html: translate('basket.terms') }} />
            <div sx={{ flex: 'auto' }} /> {/* Empty space, buttons at bottom */}
            <Flex
              sx={{
                flexDirection: 'row',
                alignSelf: 'flex-end',
                width: ['100%', '400px'],
                mt: 3,
              }}
            >
              {basket.status === BasketState.OPEN ? (
                <Button
                  variant="secondary"
                  onClick={addNewItem}
                  sx={{
                    order: [0, 1],
                    mr: [2, 0],
                    mt: 1,
                  }}
                >
                  {translate('basket.addItem')}
                </Button>
              ) : null}
              <Button sx={{ flexGrow: '1', order: [1, 0], mr: [0, 2], mt: 1 }} onClick={askSenderInfo}>
                {translate('basket.readyButton')}
              </Button>
            </Flex>
          </Box>
        </FullHeightColumn>
      </Container>
    </Layout>
  );
};

/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import combineProps from './combineServiceProps';
import { formatPrice, formatSize } from '../utils/formatters';
import { removeItem } from '../state/basket';
import { weightText4product } from '../state/catalog';
import { updateItemInBasket, itemHasHomeDelivery, askPackageCount } from '../state/parcelFlow';
import { capitalize } from '../utils/string';
import { imagePathForProduct, SizeCode } from '../state/catalog';
import Button from '../components/Button';
import * as streetAndApartment from '../utils/streetAndApartment';
import getLanguage from '../utils/getLanguage';
import { isFinlandOrAland, getLocalizedCountryName } from '../utils/countryCode';
import { isLargeBalticParcel } from '../utils/shipment';
import ConnectionInfo from '../components/express/ConnectionInfo';

const Buttons = ({ item }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const dispatch = useDispatch();
  const removeFromBasket = useCallback(() => dispatch(removeItem(item)), [dispatch, item]);
  const updateItem = useCallback(() => dispatch(updateItemInBasket(item)), [dispatch, item]);

  return (
    <Flex
      sx={{
        color: 'primary',
        fontWeight: 'medium',
      }}
    >
      <Button variant="plain" onClick={updateItem}>
        {translate('basket.updateItem')}
      </Button>
      <Button variant="plain" onClick={removeFromBasket} sx={{ ml: 3 }}>
        {translate('basket.removeItem')}
      </Button>
    </Flex>
  );
};

const Recipient = ({ item }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const language = useSelector(state => getLanguage(state));
  if (item.buySeries) {
    return null;
  }
  const hasHomeDelivery = itemHasHomeDelivery(item);
  const toAbroad = !isFinlandOrAland(item.country);
  const { recipient = {}, pickupPoint } = item;
  return (
    <div sx={{ fontSize: 2, lineHeight: 'normal' }}>
      <div>
        <span sx={{ fontWeight: 'medium' }}>{recipient.name}</span>
        {recipient.phone ? <span>, {recipient.phone}</span> : null}
        {recipient.email ? <span>, {recipient.email}</span> : null}
      </div>

      {(hasHomeDelivery || toAbroad) && (
        <>
          <div>{streetAndApartment.combine(recipient)}</div>
          <div>
            <span>{recipient.postcode}</span>
            &nbsp;
            <span>{recipient.city}</span>
          </div>
          {recipient.remarks && (
            <div>
              <span>{recipient.remarks}</span>
            </div>
          )}
          {toAbroad && (
            <div>
              <span>{getLocalizedCountryName(item.country, language)}</span>
            </div>
          )}
        </>
      )}
      {pickupPoint && (
        <>
          {(hasHomeDelivery || toAbroad) && <div>{translate('basket.pickupPoint')}:</div>}
          <div>{capitalize(pickupPoint.officeName)}</div>
          <div>
            {capitalize(pickupPoint.officeStreetAddress)}, {pickupPoint.officePostalCode}{' '}
            {capitalize(pickupPoint.officeCity)}
          </div>
        </>
      )}
    </div>
  );
};

const Product = ({ item: { product, services, buySeries, packages, repackCode } }) => {
  const language = useSelector(state => getLanguage(state));
  const translate = getTranslate(useSelector(state => state.localize));
  const itm = combineProps(product, services);
  const productHasSize = !!itm.maxSize?.width;
  const displayName = itm.localizedName?.[language] || itm.displayName;

  return (
    <Flex sx={{ width: '100%', alignItems: 'flex-start', my: 3 }}>
      <Flex
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          width: 48,
          height: 48,
          mr: 2,
        }}
      >
        <img
          sx={{
            alignSelf: 'center',
            transform: 'scale(.67)',
          }}
          src={imagePathForProduct(itm.sizeCode)}
          alt="product"
        />
      </Flex>
      <div sx={{ flexGrow: 1, mx: [2] }}>
        {buySeries ? (
          <div sx={{ fontWeight: 'medium' }}>
            {translate('buyParcel.product.seriesShipment')} {itm.quantity} x {displayName}
          </div>
        ) : (
          <div sx={{ fontWeight: 'medium' }}>
            {displayName}
            {itm.sizeCode === SizeCode.REFILL && (
              <span sx={{ fontWeight: 'normal' }}>
                , {translate('buyParcel.repack.code')}: {repackCode}
              </span>
            )}
          </div>
        )}
        {productHasSize ? (
          <div>
            {translate('buyParcel.product.maxSize')}
            &nbsp;
            {formatSize(itm.maxSize)}
            {weightText4product(itm, translate, true, services)}
          </div>
        ) : null}
        {askPackageCount(product, buySeries) ? <Box>{packages || 1} kollia</Box> : null}
      </div>
      <div sx={{ color: 'primary', fontWeight: 'medium' }}>{formatPrice(product.price)}</div>
    </Flex>
  );
};

const Service = ({ service, item }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const translate2 = (id, fallback) => translate(id, {}, { onMissingTranslation: () => fallback });
  let displayName = translate2(`buyParcel.services.${service.serviceCode}.title`, service.displayName);

  let showPrice = true;

  if (!!item && isLargeBalticParcel(item?.product) && service?.serviceCode == 'JA') {
    displayName = translate2(`buyParcel.services.JA.balticIncluded`);
    showPrice = false;
  }

  return (
    <Flex sx={{ justifyContent: 'space-between' }}>
      <div sx={{ mr: 2, fontWeight: 'medium', lineHeight: 'heading', my: '1px' }}>{displayName}</div>
      {showPrice ? (
        <div sx={{ flex: 'none', color: 'primary', fontWeight: 'medium' }}>
          {'+'}
          {formatPrice(service.price)}
        </div>
      ) : null}
    </Flex>
  );
};

const Contents = ({ item: { contents } }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  if (!contents) {
    return null;
  }
  return (
    <div>
      {translate('basket.contents')} {contents}
    </div>
  );
};

export default props => {
  const translate = getTranslate(useSelector(state => state.localize));
  const { delivery, connection } = props.item;

  return (
    <Box
      sx={{
        py: [2, 3],
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: 'border',
      }}
    >
      <Product {...props} />
      {props.item.services && (
        <Box sx={{ my: 3 }}>
          {(props.item.services || []).map(s => (
            <Service key={s.serviceCode} service={s} item={props.item} />
          ))}
        </Box>
      )}
      {delivery && (
        <div sx={{ mr: 2, fontWeight: 'normal', lineHeight: 'heading', my: '1px' }}>
          {translate(`basket.delivery.${delivery}`)}
        </div>
      )}
      <Recipient {...props} />
      <ConnectionInfo connection={connection} />
      <Contents {...props} />
      <Buttons {...props} />
    </Box>
  );
};
